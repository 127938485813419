.video-con{
    .advertisement, .advertisement2{
        margin: 44px 0 0 0;
        width: 1240px;
        height: 120px;
    }
    .advertisement2{
        height: 240px;
    }
    .video-describe{
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        .video-box{
            width: 735px;
        }
        .describe-box{
            width: 420px;
            .weekhot{
                margin-top:50px;
                width: 100%;
                border-top: 5px solid #000;
                padding-top: 10px;
                .little-title{    
                    font-size: 16px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;
                }
                .hotnews{
                    .news-item{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-bottom: 1px solid #000;
                        padding: 33.5px 0;
                        img{
                            width: 35px;
                            height: 53px;
                        }
                        span{
                            width: 357px;
                            font-size: 24px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #000000;
                            margin-left: 20px;
                            overflow: hidden;
                            display: -webkit-box;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 2;  /*要显示的行数*/
                            -webkit-box-orient: vertical;
                            word-break: break-all;
                            line-height: 33px;
                        }
                    }
                    >:nth-last-of-type(1){
                        border-width: 5px;
                    }
                }
            }
        }
    }
    .videolist{
        // margin-top: -50px;
        width: 100%;
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        >:nth-child(4n){
            .videoitem{
                margin-right: 0;
            }
        }  
    }
    .videolist:not(:nth-child(4n)) {
        margin-right: calc(4% / 3);
    }
    
    .loadMore{
        width: 611px;
        height: 68px;
        border: 2px solid #000000;
        margin: 50px auto 0;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        text-align: center;
        line-height: 64px;
        cursor: pointer;
    }
    
}
