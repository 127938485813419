.footer{
    margin-top: 100px;    
    height: 207px;
    background: #000000;
    .footer-con{
        width: 1240px;
        margin: 0 auto;
        .logbox{
            height: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 60px;
            .list{              
                
                a{
                    margin-left: 90px;
                    font-size: 15px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #EFEFEF;
                }
            }
        }
        .company{        
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #9B9B9B;
            line-height: 24px;
            margin-top: 28px;
            p, a{
                margin: 0;
                display: flex;
                align-items: center;
                color: #9B9B9B;
                img{
                    margin-right: 10px;
                }
                a{
                    color: #9B9B9B;
                    margin: 0 5px;
                }
            }
        }
    }
}