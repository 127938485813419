.hotnews-item{
    width: 100%;
    display: flex;
    padding-bottom: 31px;
    margin-bottom: 0 !important;
    align-items: center;
    
    a{
        display: block;
        position: relative;
        .loadingImg{
            width: 68px;
            height: 68px;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
    dd{
        margin-left: 22px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;  /*要显示的行数*/
        -webkit-box-orient: vertical;
        word-break: break-all;
    }
    dt{
        position: relative;
        img{
            width: 68px;
            height: 68px;
            object-fit: contain;
        }
        span{
            display: block;
            width: 13px;
            height: 13px;
            background: #000000;
            font-size: 10px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            text-align: center;
            line-height: 13px;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}

