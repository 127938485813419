.author-detail {
    padding: 60px 0 0 0;
    display: flex;
    justify-content: space-between;

    .author-detail-left {
        .author-intro {
            width: 736px;
            min-height: 240px;
            // background: #eeeeee;
            padding: 63px 58px 57px 42px;
            display: flex;
            // justify-content: space-between;
            // align-items: center;
            border: 1px solid #000000;
            .author-info {
                width: 468px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                margin-left: 48px;
                .name {
                    >span {
                        &:nth-child(1) {
                            margin-right: 20px;
                            font-size: 36px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #000000;
                            line-height: 50px;

                        }

                        &:nth-child(2) {
                            font-size: 20px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #000000;
                            line-height: 28px;

                        }
                    }
                }

                .intro {
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #000000;
                    line-height: 25px;
                    margin-top: 10px;
                    // text-align: justify;
                    // text-align-last: justify;
                    &::after {
                        display: inline-block;
                        width: fit-content;
                        content: '';
                        height: 0;
                    }
                }
            }
        }

        .author-article-count {
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: 28px;
            margin: 36px 0 8px 0;
        }

        .author-body {
            border-top: 5px solid #000000;
            margin-bottom: 49px;

            .article-item {
                padding: 49px 0;
                border-bottom: 1px solid #000000;
                width: 736px;

                .article-type {
                    display: flex;

                    >span {
                        &:nth-child(1) {
                            font-size: 16px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #000000;
                            line-height: 22px;
                            margin-right: 30px;
                        }

                        &:nth-child(2) {
                            font-size: 15px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #999999;
                            line-height: 21px;

                        }
                    }
                }

                .article-title {
                    margin: 14px 0 13px 0;
                    font-size: 26px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;
                    line-height: 37px;
                    overflow: hidden;
                    display: -webkit-box;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;  /*要显示的行数*/
                    -webkit-box-orient: vertical;
                    word-break: break-all;

                }

                .article-intro {
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 22px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            .nullImg{
                display: block;
                margin: 262px auto ;
            }
        }

        .loadMore {
            height: 68px;
            border: 2px solid #000000;
            line-height: 68px;
            text-align: center;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            cursor: pointer;
        }
    }

    .author-detail-right {
        flex: 1 1;
        padding-left: 84px;

        .we-team {
            margin-top: 50px;
            border-top: 5px solid #000000;
            border-bottom: 5px solid #000000;
            padding-bottom: 28px;
            .we-team-title {
                margin: 11px 0 30px 0;
                display: flex;
                justify-content: space-between;

                >span {
                    &:nth-child(1) {
                        font-size: 16px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #000000;
                        line-height: 22px;

                    }

                    &:nth-child(2) {
                        font-size: 15px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 21px;

                    }
                }
            }

            .we-team-item {
                display: flex;
                margin-bottom: 30px;

                >img {
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    margin-right: 20px;
                }

                .we-team-info {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;

                    >span {
                        &:nth-child(1) {
                            font-size: 20px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #000000;
                            line-height: 28px;

                        }

                        &:nth-child(2) {
                            font-size: 16px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                            line-height: 22px;

                        }
                    }
                }
            }
        }
    }
}