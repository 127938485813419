.nav-con{
    display: flex;
    justify-content: space-between;
    height: 80px;
    align-items: flex-end;
    width: 1240px;
    margin: 40px auto 0;
    .nav-logo{
        img{
            width: 189px;
            height: 94px;
        }
    }
    .nav-right{
        display: flex;
        align-items: flex-end;
        .nav-item{    
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            margin-bottom: -5px;
            margin-left: 40px;
            a,.links{
                padding: 5px 12px;
            }
            span{
                position: relative;
            }
            cursor: pointer;
            .nav-child{
                display: none;
                position: absolute;
                left: 0;
                top: 31px;
                width: 190px;
                padding: 15px 12px 0;
                background: #000000;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                z-index: 100;
                border-top: 6px solid #ffffff;
                div{
                    margin-bottom: 15px;
                }
            }
        }
        .nav-item:hover,.nav-item-active{
            a,.links{
                background: #000000;
                color: #ffffff;
            }
        }
        .nav-item-active{
            .nav-child{
                display: block;
                color: #ffffff;
            }
        }
        .nav-item-none{
            .nav-child{
                display: none;
            }
        }
        .linkantd-active{
            background: #000000;
            color: #ffffff;
        }
        .links:hover{
            .nav-child{
                display: block;
            }
        }
        .search{
            width: 16px;
            height: 16px;
            margin: 0 64px 0 52px;
            cursor: pointer;
        }
        .login{
            width: 126px;
            height: 47px;
            border: 2px solid #D43826;
            padding: 10px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #D43826;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            .arrows{
                width: 11px;
                height: 11px;
                background: url(../../assets/images/arrows.svg) no-repeat;
            }
        }

        .login:hover, .login-actve{
            color: #fff;
            background-color:  #000000;
            border: 2px solid #000000;
            .arrows{
                background: url(../../assets/images/arrows-fff.png) no-repeat;
            }
        }

        .logined{
            margin-bottom: -8px;
            position: relative;
            cursor: pointer;
            .name{
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                margin-left: 16px;
                display: inline-block;
            }
            .setting{
                position: absolute;
                left: 0;
                top: 36px;
                width: 190px;
                padding: 15px 12px 0;
                background: #000000;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                z-index: 100;
                display: none;
                border-top: 5px solid #FFFFFF;
                div{
                    margin-bottom: 15px;
                }
            }
            
            .setting-active{
                display: block;
            }
            .custom-no-avatar, .custom-avatar{
                display: inline-block;
            }
        }
    }
}
.search-content{
    width: 1000px;
    margin: 40px auto 0;
    position: relative;
    // text-align: right;
    .searchbox{
        position: relative;
        margin-top: 150px;
        .search{
            width: 1000px;
            border: none;
            border-bottom: 5px solid #000;
            padding: 10px 60px 10px 20px;
            outline: none;
        }
        ::-webkit-input-placeholder, .search{             
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #9B9B9B;
        }
        .searchbtn{
            position: absolute;
            right: 20px;
            top: 8px;
            cursor: pointer;
        }
        .delete{
            width: 18px;
            height: 18px;
            display: none;
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
        }
        .delactive{
            display: block;
        }
        .searchbtnnone{
            left: 25px;
        }
        .searchpad{
            padding-left: 80px;
        }
        .associative{
            width: 100%;
            background-color: #ffffff;
            border: 1px solid #EEEEEE;
            padding: 0 80px;
            border-top: none;
            z-index: 10;
            position: absolute;
            top: 59px;
            left:0;
            display: none;
            text-align: left;
            li{           
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                padding: 19px 0;
                border-bottom: 1px solid  #EEEEEE;
            }
            >:nth-last-of-type(1){
                border-bottom: none;
            }
        }
        .associativeshow{
            display: block;
        }
        .remind{
            font-size: 14px;
            color: #333333;
            margin-top: 18px;
            display: flex;
            align-items: center;
            span{
                font-size: 16px;
            }
            >:nth-of-type(2){
                border-bottom: 1px solid #000;
            }
            img{
                margin-right: 10px;
            }
        }
        .hot-search{
            margin: 30px 0;
            text-align: left;
            .title{           
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
            }
            .typeList{
                display: flex;
                flex-wrap: wrap;
                span{        
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;
                    margin-right: 40px;
                    margin-top: 20px;
                    cursor: pointer;
                }
            }
        }
    }
}


/* 搜索弹层样式 */
.search-mask{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 130;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
}

.search-wrap{
    position: fixed;
    z-index: 132;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    background-color: #fff;
}

.search-content{
    position: relative;
    // width: 1200px;
    // margin: 0 auto;
    .search-logo{
        display: block;
    }
}
.search-close{
    position: absolute;
    top: 40px;
    right: -84px;
    z-index: 10;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    .anticon-close{
        display: block;
        width: 56px;
        height: 56px;
        font-size: 32px;
        font-style: normal;
        line-height: 56px;
        text-align: center;
        text-transform: none;
        text-rendering: auto;
    }
}

.search-fade-enter,
.search-fade-appear {
  opacity: 0;
  animation-duration: .4s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}
.search-fade-leave {
  animation-duration: .4s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}
.search-fade-enter.search-fade-enter-active,
.search-fade-appear.search-fade-appear-active {
  animation-name: rcDialogFadeIn;
  animation-play-state: running;
}
.search-fade-leave.search-fade-leave-active {
  animation-name: rcDialogFadeOut;
  animation-play-state: running;
}
@keyframes rcDialogFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes rcDialogFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.toast-box {
    display: flex;
    justify-content: center;

    .ant-message-notice-content {
        background: #000000;
        opacity: .7;
        width: 300px !important;
        height: 97px;
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-message-custom-content {
            >span {
                &:nth-child(1) {
                    display: none;
                }

                &:nth-child(2) {
                    .toast {
                        display: flex;
                        flex-direction: column;

                        >span {
                            &:nth-child(1) {
                                font-size: 18px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #FFFFFF;
                                line-height: 25px;

                            }

                            &:nth-child(2) {
                                font-size: 14px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #FFFFFF;
                                line-height: 20px;
                                margin-top: 12px;
                            }
                        }
                    }
                }
            }
        }
    }


}