.author-con {
    margin: 77px 0 0 0;

    .author-mission,
    .author-team,
    .author-join-us {
        padding: 50px 0 54px 0;
        border-top: 5px solid #000000;
        display: flex;

        .title {
            font-size: 50px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 70px;

        }
    }

    .author-mission {
        .title {
            margin: 0 106px 0 44px;
        }

        .mission-body {
            width: 840px;
            // height: 151px;
            font-size: 32px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 45px;

        }
    }

    .author-team {
        .title {
            margin: 0 106px 0 44px;
        }

        .team-body {
            width: 57%;
            display: flex;
            flex-wrap: wrap;

            .team-item {
                width: 32%;
                display: flex;
                justify-content: space-between;
                margin-bottom: 30px;
                margin-right: 32%;

                >span {
                    &:nth-child(1) {
                        font-size: 20px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #000000;
                        line-height: 28px;
                    }

                    &:nth-child(2) {
                        font-size: 20px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #000000;
                        line-height: 28px;
                        text-decoration: underline;
                    }
                }

                &:nth-child(1),
                &:nth-child(2) {
                    >span{
                        &:nth-child(2){
                            // text-decoration: none;
                        }
                    }
                }

                &:nth-child(2n) {
                    margin-right: 0;
                }

                &:nth-last-child(1),
                &:nth-last-child(2) {
                    margin-bottom: 0;
                }
            }
        }

    }

    .author-join-us {
        margin-bottom: -54px;

        .title {
            margin: 0 106px 0 94px;
        }

        .join-us-body {
            .join-us-top {
                display: flex;
                flex-direction: column;
                margin-bottom: 48px;

                >span {
                    font-size: 20px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #000000;
                    line-height: 32px;
                }

            }

            .join-us-bottom {
                >div {
                    &:nth-child(1) {
                        font-size: 24px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #000000;
                        line-height: 33px;
                        margin-bottom: 20px;
                    }

                    &:nth-child(2) {
                        display: flex;
                        justify-content: space-between;

                        >span {
                            font-size: 20px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #000000;
                            line-height: 28px;
                            // text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
    .report{
        margin-top: 52px;
        border-top: 5px solid #000000;
        padding-top: 52px;
        display: flex;
        .title{
            font-size: 50px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 70px;
            margin: 0 106px 0 94px;
        }
        .report-con {
            width: 840px;
            // height: 151px;
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 32px;
            div{
                margin-bottom: 30px;
            }
        }
    }
}