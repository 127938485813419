.subscribe {
    height: 200px;
    border: 2px solid #000000;
    padding: 28px 20px 20px;

    .intro {
        display: flex;

        .intro-title {
            margin-left: 24px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;

            // line-height: 20px;
            .main-title {
                // line-height: 28px;
                font-weight: 500;
                font-size: 20px;
            }
        }
    }

    .inpcont {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        position: relative;

        #email {
            display: flex;

            .inputbox {
                width: 274px;
                height: 50px;
                border: 1px solid #000000;
                padding-left: 16px;
            }

            .suggest-box {
                border: 1px solid #333333;
                border-top: none;
                background: #FFFFFF;
                position: absolute;
                width: 274px;
                top: 50px;
                display: none;
                .suggest-item {
                    line-height: 26px;
                    height: 55px;
                    padding: 14px;
                    border-bottom: 1px solid #eeeeee;
                    display: flex;
                    justify-content: space-between;
                    cursor: pointer;
                    >span {
                        &:nth-child(1) {
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                            display: inline-block;
                            width: 155px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        &:nth-child(2) {
                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                            line-height: 17px;
                            border: 1px solid #333333;
                            padding: 4px 11px 3px 11px;
                            cursor: pointer;
                        }
                    }
                }
            }
            .suggest-active{
                display: block;
            }
            ::-webkit-input-placeholder,
            .inputbox {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
            }

            .desbutton {
                margin-left: 20px;
                width: 86px;
                height: 50px;
                border: 1px solid #D43826;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #D43826;
                text-align: center;
                background-color: #FFFFFF;
            }
            .btnunable{
                color: #999999;
                border: 1px solid #999999;
            }
        }

        .cancel {
            width: 382px;
            height: 50px;
            border: 1px solid #999999;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 22px;
            cursor: pointer;
        }
    }

    .ant-modal-root {
        .ant-modal-wrap {
            .ant-modal {
                .ant-modal-content {
                    .ant-modal-header {
                        border-bottom: none;
                        padding: 23px 0 0 0;

                        .ant-modal-title {
                            text-align: center;
                        }
                    }

                    .ant-modal-body {
                        display: flex;
                        flex-direction: column;
                        text-align: center;
                        padding: 23px 42px 30px 42px;

                        >span {
                            &:nth-child(1) {
                                font-size: 20px;
                                font-family: PingFangSC-Medium, PingFang SC;
                                font-weight: 500;
                                color: #000000;
                                line-height: 28px;

                            }

                            &:nth-child(2) {
                                font-size: 14px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #000000;
                                line-height: 20px;
                                margin: 7px 0 30px 0;
                            }

                        }

                        .btns {
                            display: flex;
                            justify-content: space-around;

                            >div {
                                &:nth-child(1) {
                                    width: 146px;
                                    height: 42px;
                                    border: 1px solid #999999;
                                    font-size: 16px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #999999;
                                    line-height: 42px;
                                    cursor: pointer;
                                }

                                &:nth-child(2) {
                                    width: 146px;
                                    height: 42px;
                                    border: 1px solid #D43826;
                                    font-size: 16px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #D43826;
                                    line-height: 42px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.toast-box {
    display: flex;
    justify-content: center;

    .ant-message-notice-content {
        background: #000000;
        opacity: .7;
        width: 362px;
        height: 97px;
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-message-custom-content {
            >span {
                &:nth-child(1) {
                    display: none;
                }

                &:nth-child(2) {
                    .toast {
                        display: flex;
                        flex-direction: column;

                        >span {
                            &:nth-child(1) {
                                font-size: 18px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #FFFFFF;
                                line-height: 25px;

                            }

                            &:nth-child(2) {
                                font-size: 14px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #FFFFFF;
                                line-height: 20px;
                                margin-top: 12px;
                            }
                        }
                    }
                }
            }
        }
    }


}
// .ant-row .ant-form-item .ant-form-item-with-help .ant-form-item-has-error, .ant-select-selector{
//     width: 274px;
//     height: 50px;
//     border: 1px solid #000000;
// }
// .ant-select-item-option-active:not(.ant-select-item-option-disabled){
//     background-color: #FFFFFF !important;
// }
// .ant-select-multiple .ant-select-selection-item{
//     width: 100%;
//     height: 100%;
//     background-color: transparent;
//     border: 0;
//     display: flex;
//     align-items: center;
// }
// .ant-select-selection-item-remove{
//     display: none !important;
// }
// .ant-select-dropdown-hidden{
//     display: block;
// }
// .ant-select-item-option-content{
//     line-height: 26px;
//     height: 55px;
//     padding: 14px;
//     border-bottom: 1px solid #eeeeee;
//     display: flex;
//     justify-content: space-between;
//     cursor: pointer;
//     .emailtitle {
//         font-size: 14px;
//         font-family: PingFangSC-Regular, PingFang SC;
//         font-weight: 400;
//         color: #333333;
//         display: inline-block;
//         width: 155px;
//         overflow: hidden ;
//         white-space: nowrap;
//         text-overflow: ellipsis;
//     }

//     .btn {
//         font-size: 12px;
//         font-family: PingFangSC-Regular, PingFang SC;
//         font-weight: 400;
//         color: #333333;
//         line-height: 17px;
//         border: 1px solid #333333;
//         padding: 4px 11px 3px 11px;
//         cursor: pointer;
//     }
// }
// .ant-select-item-option-state, .ant-select-item-empty, .ant-empty-image svg{
//     display: none;
//     width: 0;
//     height: 0;
//     padding: 0 !important;
//     margin: 0;
// }
// .ant-select-dropdown{
//     padding: 0 !important;
// }