.videoitem{
    width: 295px;
    border-top: 1px solid #000000;
    margin-top: 50px;
    margin-right: 20px;
    .little-title{
        margin: 17px 0 20px 0;
    }
    .title-con{    
        height: 100px; 
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 33px;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;  /*要显示的行数*/
        -webkit-box-orient: vertical;
        word-break: break-all;
    }
    .video{
        position: relative;
        width: 295px;
        height: 178px;
        margin-top: 30px;
        .playBtn{
            width: 0;
            height: 0;
            border: 16px solid transparent;
            border-left-color: #ffffff;
            opacity: .8;
            position: absolute;
            left: 50%;
            margin-left: -8px;
            top: 50%;
            margin-top: -8px;
        }
        img{
            width: 295px;
            height: 178px;
        }
        .loadingImg{
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    
}