.login-box{
    border-top: 5px solid #000;
    margin-top: 77px;
    display: flex;
    .title{      
        font-size: 50px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        margin: 60px 0 0 160px;
    }
    .content{
        margin-left: 125px;
        input{
            width: 636px;
            border: none;
            outline: none;
            margin-top: 60px;
            border-bottom: 1px solid #000;
            padding: 15px 0;

        }
        ::-webkit-input-placeholder, input{             
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #9B9B9B;
        }
        .statusbox{
            display: flex;
            justify-content: space-between;
            margin-top: 10px;      
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            .register{
                color: #D43826;
                cursor: pointer;
            }
            .forget{
                color: #9B9B9B;
                cursor: pointer;
            }
        }
        .btnlogin{
            width: 158px;
            height: 65px;
            border: 2px solid #D43826;          
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #D43826;
            line-height: 61px;
            padding: 10px;
            margin: 74px 0 143px 0;
            display: flex;
            justify-content: space-between;
            background-color: #ffffff;
            cursor: pointer;
            .arrows{
                width: 11px;
                height: 11px;
            }
        }
        .btnlogin:focus{
            outline: none !important;
        }
    }
    
}