.information-con{
    .advertisement,.advertisement2{
        margin-top: 44px;
        width: 1240px;
        height: 120px;
    }
    .advertisement2{
        height: 240px;
    }
    .content{
        margin-top: 50px;
        .little-title{                   
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
        }
        .cover-flash{
            display: flex;
            justify-content: space-between;
            .cover-flash-left {
                display: flex;
                flex-direction: column;
            }
            .cover{
                width: 735px;
                .cover-con{
                    padding: 30px;
                    height: 276px;
                    background: #000000;
                    .fm{
                        font-size: 20px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #FFFFFF;
                        margin-top: 10px;
                    }
                    .cover-border{
                        width: 169px;
                        height: 4px;
                        background: #FFFFFF;
                    }
                    .title{
                        font-size: 36px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #FFFFFF;
                        margin-top: 36px;
                        overflow: hidden;
                        display: -webkit-box;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 2;  /*要显示的行数*/
                        -webkit-box-orient: vertical;
                        word-break: break-all;
                        line-height: 50px;
                    }
                    .con{                       
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        margin-top: 13px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
                .img-box{
                    position: relative;
                    width: 735px;
                    height: 408px;
                    .loadingImg{
                        height: 408px;
                        width: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }
                .coverImg{
                    height: 408px;
                    width: 100%;
                }
            }
            .flash{
                flex: 1;
                padding-left: 84px;
                display: flex;
                flex-direction: column;
                .papergpt-banner {
                    width: 100%;
                    margin-bottom: 51px;
                }
                .flash-list{
                    margin-top: 50px;
                    border-top: 5px solid #000;
                    padding-top: 10px;
                    .title{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        span{   
                            font-size: 16px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #000000;
                        }
                        a{   
                            font-size: 15px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                        }
                    }
                    .list{
                        .item{    
                            font-size: 20px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #000000;
                            margin-top: 26px;
                            overflow: hidden;
                            display: -webkit-box;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 2;  /*要显示的行数*/
                            -webkit-box-orient: vertical;
                            word-break: break-all;
                            cursor: pointer;
                        }
                    }
                } 
            }
            .lastes-con{
                width: 735px;
                border-top: 5px solid #000000;
                border-bottom: 5px solid #000000;
                padding: 10px 0 50px 0;
                margin-top: 50px;
                flex: 1;
                .lastest-list{
                    .last-item{
                        margin: 0;
                        display: flex;
                        border-bottom: 1px solid #000000;
                        padding: 51px 0;
                        a{
                            display: block;
                        }
                        dd{
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            margin-bottom: 0;
                            h1{    
                                font-size: 28px;
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;
                                color: #000000;
                                overflow: hidden;
                                display: -webkit-box;
                                text-overflow: ellipsis;
                                -webkit-line-clamp: 2;  /*要显示的行数*/
                                -webkit-box-orient: vertical;
                                word-break: break-all;
                                line-height: 40px;
                            }
                            .con{    
                                font-size: 16px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #333333;
                                margin-top: 18px;
                                overflow: hidden;
                                display: -webkit-box;
                                text-overflow: ellipsis;
                                -webkit-line-clamp: 2;  /*要显示的行数*/
                                -webkit-box-orient: vertical;
                                word-break: break-all;
                            }
                            .type-date{    
                                font-size: 16px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #999999;
                                display: flex;
                                justify-content: space-between;
                                margin-top: 10px;
                                span{
                                    margin-right: 28px;
                                }
                            }
                        }
                        dt{
                            margin-left: 22px;
                            position: relative;
                            a{
                                img{
                                    width: 316px;
                                    height: 187px;
                                    object-fit: contain;
                                }
                            }
                            .loadingImg{
                                width: 316px;
                                height: 187px;
                                position: absolute;
                                left: 0;
                                top: 0;
                            }
                        }
                    }
                    >:nth-of-type(1){
                        padding-top: 20px;
                    }
                    >:nth-last-of-type(1){
                        padding-bottom: 0;
                        border-bottom: none;
                    }
                }
            }
            .news-con{
                width: 420px;
                border-top: 5px solid #000000;
                border-bottom: 5px solid #000000;
                // padding: 10px 0 50px 0;
                padding-top: 10px;
                margin-top: 50px;
                flex: 1;
                .newlist{
                    .hotnews-item:nth-last-of-type(1){
                        padding-bottom: 50px;
                        border-bottom: 5px solid #000000;
                        margin-bottom: 0;
                    }
                    .hotnews-item:nth-of-type(1){
                        padding-top: 20px;
                    }
                }
                
                .title{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-top: 10px;
                    span{   
                        font-size: 16px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #000000;
                    }
                    a{   
                        font-size: 15px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                    }
                }
                
            }
        }
        .lastest-news{
            display: flex;
            justify-content: space-between;
            margin-top: 50px ;
            // .lastes-con{
            //     width: 735px;
            //     border-top: 5px solid #000000;
            //     border-bottom: 5px solid #000000;
            //     padding: 10px 0 50px 0;
            //     .lastest-list{
            //         .last-item{
            //             margin: 0;
            //             display: flex;
            //             border-bottom: 1px solid #000000;
            //             padding: 51px 0;
            //             a{
            //                 display: block;
            //             }
            //             dd{
            //                 flex: 1;
            //                 display: flex;
            //                 flex-direction: column;
            //                 justify-content: space-between;
            //                 margin-bottom: 0;
            //                 h1{    
            //                     font-size: 28px;
            //                     font-family: PingFangSC-Semibold, PingFang SC;
            //                     font-weight: 600;
            //                     color: #000000;
            //                     overflow: hidden;
            //                     display: -webkit-box;
            //                     text-overflow: ellipsis;
            //                     -webkit-line-clamp: 2;  /*要显示的行数*/
            //                     -webkit-box-orient: vertical;
            //                     word-break: break-all;
            //                     line-height: 40px;
            //                 }
            //                 .con{    
            //                     font-size: 16px;
            //                     font-family: PingFangSC-Regular, PingFang SC;
            //                     font-weight: 400;
            //                     color: #333333;
            //                     margin-top: 18px;
            //                     overflow: hidden;
            //                     display: -webkit-box;
            //                     text-overflow: ellipsis;
            //                     -webkit-line-clamp: 2;  /*要显示的行数*/
            //                     -webkit-box-orient: vertical;
            //                     word-break: break-all;
            //                 }
            //                 .type-date{    
            //                     font-size: 16px;
            //                     font-family: PingFangSC-Regular, PingFang SC;
            //                     font-weight: 400;
            //                     color: #999999;
            //                     display: flex;
            //                     justify-content: space-between;
            //                     margin-top: 10px;
            //                     span{
            //                         margin-right: 28px;
            //                     }
            //                 }
            //             }
            //             dt{
            //                 margin-left: 22px;
            //                 position: relative;
            //                 a{
            //                     img{
            //                         width: 316px;
            //                         height: 187px;
            //                         object-fit: contain;
            //                     }
            //                 }
            //                 .loadingImg{
            //                     width: 316px;
            //                     height: 187px;
            //                     position: absolute;
            //                     left: 0;
            //                     top: 0;
            //                 }
            //             }
            //         }
            //         >:nth-of-type(1){
            //             padding-top: 20px;
            //         }
            //         >:nth-last-of-type(1){
            //             padding-bottom: 0;
            //             border-bottom: none;
            //         }
            //     }
            // }
            // .news-con{
            //     width: 420px;
            //     border-top: 5px solid #000000;
            //     border-bottom: 5px solid #000000;
            //     padding: 10px 0 50px 0;
            //     .newlist{
            //         .hotnews-item:nth-last-of-type(1){
            //             padding-bottom: 50px;
            //             border-bottom: 5px solid #000000;
            //             margin-bottom: 0;
            //         }
            //         .hotnews-item:nth-of-type(1){
            //             padding-top: 20px;
            //         }
            //     }
                
            //     .title{
            //         display: flex;
            //         justify-content: space-between;
            //         align-items: center;
            //         padding-top: 10px;
            //         span{   
            //             font-size: 16px;
            //             font-family: PingFangSC-Semibold, PingFang SC;
            //             font-weight: 600;
            //             color: #000000;
            //         }
            //         a{   
            //             font-size: 15px;
            //             font-family: PingFangSC-Regular, PingFang SC;
            //             font-weight: 400;
            //             color: #333333;
            //         }
            //     }
                
            // }
        }
        .typelist{
            margin-top: 50px;
            display: flex;
            justify-content: space-between;
            border-bottom: 5px solid #000000;
            padding-bottom: 50px; 
            .typeitem{
                width: 295px;
                border-top: 1px solid #000000;
                .little-title{
                    margin: 17px 0 30px 0;
                    height: 25px;
                }
                .title-con{     
                    font-size: 24px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;
                    overflow: hidden;
                    display: -webkit-box;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 3;  /*要显示的行数*/
                    -webkit-box-orient: vertical;
                    word-break: break-all;
                    margin-bottom: 30px;
                    height: 111px;
                }
                .realimg{
                    width: 295px;
                    height: 178px;
                    object-fit: contain;
                }
                .img-box{
                    display: block;
                    width: 295px;
                    height: 178px;
                    position: relative;
                    .loadingImg{
                        width: 295px;
                        height: 178px;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }
            }
        }
        .inforlist{
            width: 926px;
            .infor-item{
                margin: 0;
                display: flex;
                border-bottom: 1px solid #000000;
                padding: 50px 0;
                dd{
                    width: 609px;
                    margin-right: 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .typename{
                        font-size: 15px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #000000;
                    }
                    h1{    
                        font-size: 28px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #000000;
                        overflow: hidden;
                        display: -webkit-box;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 2;  /*要显示的行数*/
                        -webkit-box-orient: vertical;
                        // margin-top: 10px;
                        line-height: 37px;
                    }
                    .con{    
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        margin-top: 18px;
                        overflow: hidden;
                        text-overflow:ellipsis;
                        white-space: nowrap;
                    }
                    .type{    
                        font-size: 15px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #999999;
                        margin-top: 18px;
                        span{
                            margin-right: 44px;
                        }
                    }
                }
                dt{
                    position: relative;
                    a{
                        img{
                            width: 296px;
                            height: 178px;
                            object-fit: contain;
                        }
                    }
                    .loadingImg{
                        width: 296px;
                        height: 178px;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }
            }
            // .infor-item:nth-of-type(4n){
            //     border-bottom: none;
            // }
            .item-noborder:nth-of-type(4n){
                border-bottom: none;
            }
            .inforlistimg{
                width: 926px;
                height: 120px;
            }
            // .inforlistimg:nth-of-type(1){
            //     display: none;
            // }
            .loadbox{
                border-top: 1px solid #000;
            }
            .loadMore{
                height: 68px;
                border: 2px solid #000000;
                line-height: 64px;
                text-align: center;    
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #000000;
                margin-top: 50px;
                cursor: pointer;
            }
        } 
    }
}
