.emtechcolumn{
    .advertisement{
        margin-top: 44px;
        width: 1240px;
        height: 120px;
    }
    .resultList{
        position: relative;
        text-align: left;
        width: 1000px;
        margin: 0 auto;
        .check-box{
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #000;
            padding-bottom: 5px;
            margin-top: 50px;
            .tabs{
                span{          
                    font-size: 20px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #000000;
                    padding: 5px 10px;
                    cursor: pointer;
                    margin-right: 30px;
                }
                .active{
                    border-bottom: 3px solid #000;
                }
            }
        }
    }
    .movieList{
        // margin-top: -50px;
        width: 100%;
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        padding: 0 37px;
        >:nth-child(3n){
            .videoitem{
                margin-right: 0;
            }
        } 
        .videoitem{
            border-top: none;
            padding-bottom: 50px;
            margin-top: 18px;
            border-bottom: 1px solid #000000;
        } 
        >a:nth-of-type(1), >a:nth-of-type(2), >a:nth-of-type(3){
            margin-top: 32px;
        }
    }
    .movieList:not(:nth-child(3n)) {
        margin-right: calc(4% / 3);
    }
    .null-img{
        display: block;
        margin: 262px auto 0;
    }
    .loadMore {
        width: 100%;
        height: 68px;
        border: 2px solid #000000;
        line-height: 68px;
        text-align: center;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        cursor: pointer;
        margin: 50px auto 0;
    }
    .loadMoreactive{
        width: 611px;
        height: 68px;
    }
}