.search-con{
    width: 1000px;
    margin: 60px auto 0;
    position: relative;
    // text-align: right;
    .search-del{
        margin-right: -94px;
        cursor: pointer;
    }
    .searchbox{
        position: relative;
        margin-top: 80px;
        .search{
            width: 100%;
            border: none;
            border-bottom: 5px solid #000;
            padding: 10px 60px 10px 80px;
            outline: none;
        }
        ::-webkit-input-placeholder, .search{             
            font-size: 36px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
        }
        .searchbtn{
            width: 36px;
            height: 36px;
            position: absolute;
            left: 25px;
            top: 22px;
            cursor: pointer;
        }
        .delete{
            width: 18px;
            height: 18px;
            position: absolute;
            right: 20px;
            top: 36px;
            cursor: pointer;
        }
       
        .searchbtnnone{
            left: 25px;
        }
        // .searchpad{
        //     padding-left: 80px;
        // }
        .associative{
            width: 100%;
            background-color: #ffffff;
            border: 1px solid #EEEEEE;
            padding: 0 80px;
            border-top: none;
            z-index: 10;
            position: absolute;
            top: 82px;
            left:0;
            display: none;
            text-align: left;
            li{           
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                padding: 19px 0;
                border-bottom: 1px solid  #EEEEEE;
            }
            >:nth-last-of-type(1){
                border-bottom: none;
            }
        }
        .associativeshow{
            display: block;
        }
        .remind{
            font-size: 14px;
            color: #333333;
            margin-top: 18px;
            display: flex;
            align-items: center;
            span{
                font-size: 16px;
            }
            >:nth-of-type(2){
                border-bottom: 1px solid #000;
            }
            img{
                margin-right: 10px;
            }
        }
        .hot-search{
            margin: 30px 0;
            text-align: left;
            .title{           
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
            }
            .typeList{
                display: flex;
                flex-wrap: wrap;
                span{        
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;
                    margin-right: 40px;
                    margin-top: 20px;
                    cursor: pointer;
                }
            }
        }
        .resultList{
            position: relative;
            text-align: left;
            .check-box{
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #000;
                padding-bottom: 5px;
                margin-top: 56px;
                .tabs{
                   
                    span{          
                        font-size: 20px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #000000;
                        padding: 5px 10px;
                        cursor: pointer;
                    }
                    .active{
                        border-bottom: 3px solid #000;
                    }
                }
                
            }
            .select{
                width: 90px;
                height: 28px;
                overflow: hidden;
                background: #FFFFFF;
                border: 1px solid #000000;
                padding: 0 10px;
                position: absolute;
                right: 0;
                top: 0;
                cursor: pointer;
                div{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height:  28px;
                    img{
                        width: 9px;
                        height: 5px;
                    }
                }
                .correlation{
                    border-bottom: 1px solid #D8D8D8; 
                }
               .arrow{
                   display: inline-block;
                   width: 9px;
                   height: 6px;
                   background: url('../../assets/images/packup.png');
               }
            }
            .selectopen{
                height: 56px;
                overflow: visible;
            }
            .select:hover{
                height: 56px;
                overflow: visible;
                .arrow{
                    background: url('../../assets/images/expansion.png');
                }
            }

            .items{
                border-bottom: 1px solid #000;
                padding: 38px 0 50px 0;
                h1{                   
                    font-size: 28px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;
                    margin-bottom: 10px;
                    overflow: hidden;
                    display: -webkit-box;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;  /*要显示的行数*/
                    -webkit-box-orient: vertical;
                    word-break: break-all;
                }
                .intro{     
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }
                .time{
                    margin-top: 18px;
                }
            }
        }
        
    }
    .null-img{
        display: block;
        margin: 262px auto 0;
    }
}