.searchItem{
    margin: 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #000000;
    padding: 50px 0;
    width: 100%;
    dd{
        width: 609px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .type{        
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
        }
        h1{         
            font-size: 26px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            // margin: 10px 0;
            overflow: hidden;
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;  /*要显示的行数*/
            -webkit-box-orient: vertical;
            word-break: break-all;
            line-height: 37px;
        }
        .con{    
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            margin-top: 18px;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
        }
        .date{       
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            margin-top: 18px;
            span:nth-of-type(1){
                margin-right: 24px;
            }
        }
    }
    dt{
        width: 296px;
        height: 178px;
        position: relative;
        .defaultImg{
            width: 296px;
            height: 178px;
            object-fit: contain;            
        }
        .loadingImg{
            width: 296px;
            height: 178px;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}