.contract{
    .title{
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        text-align: center;
        margin: 100px 0 70px 0;
    }
    .content{
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
    
    }
    .agreebtn{
        display: block;
        width: 140px;
        height: 50px;
        background: #FFFFFF;
        border: 2px solid #D43826;    
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #D43826;
        text-align: center;
        line-height: 46px;
        margin: 100px auto 0;
    }
}