
.swiper-container {
    height: fit-content;
    width: 735px;
  }

  .swiper-slide {
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    // display: -webkit-box;
    // display: -ms-flexbox;
    // display: -webkit-flex;
    // display: flex;
    // -webkit-box-pack: center;
    // -ms-flex-pack: center;
    // -webkit-justify-content: center;
    // justify-content: center;
    // -webkit-box-align: center;
    // -ms-flex-align: center;
    // -webkit-align-items: center;
    // align-items: center;
    .video{
        width: 100%;
        height: 413px;
        position: relative;
        a{
          position: relative;
          .hotvideo{
            width: 100%;
            height: 413px;
            object-fit: fill;
          }
          .videoPlay{
            width: 0;
            height: 0;
            border: 27px solid transparent;
            border-left-color: #fff;
            opacity: .8;
            position: absolute;
            left: 50%;
            margin-left: -13.5px;
            top: 50%;
            margin-top: -13.5px;
          }
        }
        .loadingImg{
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 413px;
        }
        
    }
    .video-intro{
        .intro{
            display: flex;
            justify-content: space-between;
            .introcon{
                width: 169px;
                border-top: 4px solid #ffffff;   
                font-size: 20px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                padding-top: 10px;
                margin-bottom: 20px;
            }
            .btnbox{
                span{
                    display: inline-block;
                    width: 22px;
                    height: 22px;
                    background: #000000;
                    border: 1px solid #FFFFFF;
                    color: #FFFFFF;
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    margin-left: 18px;
                    text-align: center;
                    line-height: 20px;
                    cursor: pointer;
                }
                span:hover{
                    color: #000000;
                    background-color:#FFFFFF;
                }
            }
        }
        width: 100%;
        height: 297px;
        background: #000000;
        padding: 30px;
        .video-title{                   
            font-size: 36px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            overflow: hidden;
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;  /*要显示的行数*/
            -webkit-box-orient: vertical;
            word-break: break-all;
        }
    }
  }

  .swiper-pagination-bullet {
    width: 22px;
    height: 22px;
    background: #000000 !important;
    border: 1px solid #FFFFFF !important;
    color: #FFFFFF !important;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    margin-left: 18px;
    text-align: center;
    line-height: 20px;
    opacity: 1;
    border-radius: 0%;
  }

  .swiper-pagination-bullet-active {
    color: #000000 !important;
    background-color:#FFFFFF !important;
  }
  .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
    width: fit-content;
    left: 615px;
    bottom: 240px;
  }