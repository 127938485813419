
.video-detail{
    margin-top: 50px;
    .main-video-box{
        width: 100%;
        height: 540px;
        background-color: #000000;
        text-align: center;
        .main-video, embed{
            width: 960px;
            height: 540px;
            margin: 0 auto;
            object-fit: contain;
        }
        
    }
    .title-share{ 
        background-color: #000000;
        // padding: 18px 0 23px 80px;
        .title-box{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 1200px;
            margin: 0 auto;
            .title{  
                // width: 795px;
                font-size: 36px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                text-align: left;
                // margin-left: -80px;
                // margin: 0 -80px;
                padding-top: 18px;
                .cont{
                    overflow: hidden;
                    display: -webkit-box;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;  /*要显示的行数*/
                    -webkit-box-orient: vertical;
                    word-break: break-all;
                }
                .time{
                    // width: 960px;
                    margin: 0 auto;
                    // margin-left: -80px;      
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    padding-bottom: 23px;
                }
            }
            .share-box{
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                padding-left: 80px;
                img{
                    margin-left: 20px;     
                }
               
            }
        }
        
        
    }
    .videolist{
        width: 1240px;
        margin: 0 auto;
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        >:nth-child(4n){
            .videoitem{
                margin-right: 0;
            }
        }  
    }
    // .videolist:not(:nth-child(4n)) {
    //     margin-right: calc(4% / 3);
    // }
    
}