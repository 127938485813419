.auther{
    display: flex;
    margin-top: 30px;
    align-items: center;
    dt{
        margin-right: 20px;
        img{
            width: 80px;
            height: 80px;
        }
    }
    dd{
        // flex: 1;
        p{
            margin: 0;
        }
        .title{    
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
        }
        .intro{
            width: 320px;   
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}