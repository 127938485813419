.custom-avatar,.custom-no-avatar{
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: #DFDFDF;
    img{
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
    img.no-avatar{
        width: 60px !important;
        height: 48px !important;
    }
}
