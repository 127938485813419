.share-container{
    width: 1170px;
    margin: 0 auto;
    padding: 40px;
}

@media (max-width: 768px){
    .video-con, embed{
        width: 100%;
        height: 210px;
        object-fit: fill;
    }
    .share-container{
        width: 100%;
        padding: 0;
        min-height: -webkit-fill-available;
        display: flex;
        flex-direction: column;
        .share-cont{
            flex: 1;
            .videocon{
                position: relative;
                width: 100%;
                height: fit-content;
                .mengceng{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: rgba($color: #000000, $alpha: 0.3);
                }
                .vipbtn{
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    width: 300px;
                    height: 40px;
                    background: #000;
                    border-radius: 4px;
                    text-align: center;
                    line-height: 40px;
                    position: absolute;
                    bottom: 50%;
                    margin-bottom: -20px;
                    left: 50%;
                    margin-left: -150px;
                    z-index: 10;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        margin-right: 5px;
                    }
                }
            }
        }
        .text-container {
            padding: 20px;
            .banner-img{
                width: 100%;
                display: inline-block;
            }
            .title {
                font-size: 20px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 28px;
            }
            .title2{
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
            }
            .videotitle{
                margin-top: 0;
            }
            .sub-title{
                margin: 15px 0;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
            }
        }
        .vip{
            position: relative;
            .vipcont{
                // height: 150px;
                max-height: 800px;
                overflow: hidden;
                .abstract{
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;
                }
                hr{
                    border: none;
                }
            }
            .vipbtn{
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                width: 300px;
                height: 40px;
                background: #0A014A;
                border-radius: 4px;
                text-align: center;
                line-height: 40px;
                position: absolute;
                bottom: -20px;
                left: 50%;
                margin-left: -150px;
            }
            .vipbtncon{
                width: 100%;
                height: 140px;
                background: url(../../assets/images/appimg/zhezhao.png) no-repeat;
                position: absolute;
                bottom: 0;
                .vipbtn{
                    bottom: 0;
                }
            }
        }
    }
    // 分享的底部
    .share-footer{
        width: 100%;
        padding: 20px;
        // position: fixed;
        bottom: 0;
        left: 0;
        display: flex;
        // justify-content: space-between;
        align-items: center;
        background-color: #fff;
        > img{
            width: 50px;
            height: 50px;
        }
        > div.footer-rt{
            // width: calc(100% - 110px);
            flex: 1;
            margin-left: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // >div{
            //     flex: 1;
            // }
            > p{
                margin-bottom: 0 !important;
                line-height: 22px;
                font-size: 12px;
                color: #666;
            }
            .title{
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                margin-bottom: 0 !important;
            }
            .title2{
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                margin-bottom: 0 !important;
            }
            > button{
                background-color: #fff;
                border: 1px solid #0A014A;
                color: #0A014A;
                width: 60px;
                height: 30px;
                border: 1px solid #0A014A;
                // line-height: 38px;
                padding: 0;
                margin: 8px 0 15px 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}