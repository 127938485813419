.h5agree{
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;
    overflow: hidden;
}
.question{
    .title{
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        text-align: center;
    } 
    .req{
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #222222;
        margin: 20px 0 10px;
    }
    .res, .lastres{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #F0F0F0;
    }
    .lastres{
        margin-top: 20px;
    }
    a{
        color: blue;
    }
}
.contactus{
    .title{
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #222222;
        text-align: center;
        margin-top: 61px;
    }
   
    .bor{
        width: 30px;
        height: 2px;
        background: #222222;
        margin: 30px auto 0;
    }
    .wechatcode{
        margin: 40px auto 15px;
        width: 150px;
        display: block;
    }
    .addwechat{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        text-align: center;
    }
    .res{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        text-align: center;
        margin-top: 10px;
    }
    .sendemail{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        line-height: 20px;
        text-align: center;
        margin-top: 10px;
    }
}

.aboutus{
    padding: 0;
    overflow: hidden;
    .title{
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #222222;
        text-align: center;
        margin: 20px 0 15px 0;
    }
    .title2{
        margin-top: 30px;
    }
    .logo{
        width: 80px;
        display: block;
        margin: 50px auto 0;
    }
    .logo2{
        width: 172px;
        display: block;
        margin: 50px auto 0;
    }
    .intro{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        line-height: 20px;
        padding: 0 15px;
    }
    .bor{
        width: 100%;
        height: 10px;
        background: #F9F9F9;
        margin-top: 50px;
    }
}
.useragreement, .privacy{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    padding-bottom: 20px;
    .preface{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        margin-top: 31px;
    }
    .title{
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        line-height: 22px;
        margin: 20px 0;
    }
    a{
        color: blue;
    }
}