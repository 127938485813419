.newsflash-con{
    .advertisement{
        margin: 44px 0 0 0;
        height: 120px;
        width: 100%;
    }
    .content{
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        .flashlist{
            width: 736px;
            border-top: 5px solid #000;
            .date{                
                font-size: 16px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                background-color: #000;
                width: fit-content;
                padding: 0 3px;
                height: 22px;
                line-height: 22px;
            }
            .flashlist-item{
                border-bottom: 1px solid #000;
                padding: 38px 0 50px 0;
                h1{                   
                    font-size: 28px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;
                    margin-bottom: 10px;
                    overflow: hidden;
                    display: -webkit-box;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;  /*要显示的行数*/
                    -webkit-box-orient: vertical;
                    word-break: break-all;
                }
                .intro{     
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }
                .time-share{
                    display: flex;
                    justify-content: space-between;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    margin-top: 15px;
                    .share-box{
                        img{
                            margin-left: 20px;
                        }
                    }
                }
            }
            .loadMore{
                width: 737px;
                height: 68px;
                border: 2px solid #000000;
                text-align: center;
                line-height: 64px;
                margin-top: 50px;               
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #000000;
                cursor: pointer;
            }
        }
        .content-right{
            width: 420px;
            .little-title{
                border-top: 5px solid #000;
                margin-top: 50px;
                padding: 10px 0 20px 0;                
                font-size: 16px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #000000;
                display: flex;
                justify-content: space-between;
                width: 100%;
            }
            .newlist{
                .hotnews-item:nth-last-of-type(1){
                    margin-bottom: 0;
                }
                .hotnews-item:nth-of-type(1){
                    // padding-top: 20px;
                }
            }
            .title{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .little-title{
                    padding-bottom: 0;
                }
                span{   
                    font-size: 16px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;
                }
                a{   
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }
            }
            
            .littleimg{
                margin-top: 30px;
                border-top: 5px solid #000;
                padding-top: 50px;
                img{
                    width: 100%;
                    height: 235px;
                }
            }
        }
    }
}