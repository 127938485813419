.regist-box {
    border-top: 5px solid #000;
    margin-top: 77px;
    display: flex;

    .title {
        font-size: 50px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        margin: 60px 0 0 160px;
    }
    .forget{
        margin-left: 60px;
    }
    .content {
        margin-left: 125px;

        input, input:focus {
            width: 636px;
            border: none;
            outline: none;
            margin-top: 60px;
            border-bottom: 1px solid #000;
            padding: 15px 0;
            border-radius: 0;
        }

        ::-webkit-input-placeholder,
        input {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #9B9B9B;
        }
     
        #regist {
            .item {
                .vercode {
                    margin: 70px 0 0 7px;
                    min-width: 120px;
                    height: 38px;
                    background: #000000;
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 25px;
                   
                }
                .pwdcon{
                    position: relative;
                    span{
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #999999;
                        position: absolute;
                        right: 0;
                        top: 75px;
                    }
                    .pwdplace{
                        display: none;
                    }
                }
            }
            .ant-checkbox-wrapper{
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                span.contract{
                    color: #333333;
                }
            }
        }
        #regist_code{
            position: relative;
            .vercode{
                position: absolute;
                right: 0;
            }
        }
        .foot {
            display: flex;
            margin-top: 70px;

            .btnregist {
                width: 158px;
                height: 65px;
                border: 2px solid #D43826;
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #D43826;
                line-height: 61px;
                padding: 10px;
                display: flex;
                justify-content: space-between;
                cursor: pointer;
                background-color: #FFFFFF;
                .arrows {
                    width: 11px;
                    height: 11px;
                }
            }
            .btnregist:focus{
                outline: none !important;
            }
            .login {
                margin-left: 40px;
                display: flex;
                flex-direction: column-reverse;

                >span {
                    &:nth-child(2) {
                        width: 156px;
                        height: 30px;
                        font-size: 18px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                        line-height: 25px;
                        >a{
                            font-style: normal;
                            color: #D43826;
                            font-size: 22px;
                        }
                    }
                }
            }
        }


    }

}