.detail-con{
    margin-top: 50px;
    .title-con{
        display: flex;
        min-height: 280px;
        align-items: center;
        background: #000000;
        padding: 36px 45px;
        .titleleft{
            width: 735px;
            min-height: 280px;
            padding-right: 45px;
            .type-title{
                width: 169px;
                padding-top: 5px;
                border-top: 4px solid #ffffff; 
                font-size: 16px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
            }
            .title{            
                font-size: 36px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                margin-top: 29px;
                line-height: 50px;
                // overflow: hidden;
                // display: -webkit-box;
                // text-overflow: ellipsis;
                // -webkit-line-clamp: 2;  /*要显示的行数*/
                // -webkit-box-orient: vertical;
                // word-break: break-all;
            }
            .little-title{      
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                margin-top: 13px;
                line-height: 25px;
                padding-right: 55px;
            }
        }
        img{
            width: 504px;
            height: 280px;
            object-fit: contain;
        }
    }
    .article-con{
        padding-left: 47px;
        margin-top: 47px;
        display: flex;
        justify-content: space-between;
        .article-left{
            width: 636px;
            .content{
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 26px;
            }
            .share-box{
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                display: flex;
                justify-content: flex-end;
                border-bottom: 2px solid #000;
                padding-bottom: 19px;
                img{
                    margin-left: 20px;     
                }
               
            }
            .time{
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                border-bottom: 2px solid #000;
                padding-bottom: 18px;
            }
            .auther{
                .intro{
                    width: 536px !important;
                }
            }
        }
        .article-right{
            width: 421px;
            .news-con{
                .little-title{             
                    font-size: 18px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;
                    margin-bottom: 22px;
                    border-top: 5px solid #000;
                    padding-top: 10px;
                }
                border-bottom: 5px solid #000;
            }
            .banner{
                width: 424px;
                height: 235px;
                margin-top: 50px;
            }
        }
    }
    .recommend-list{
        margin-top: 100px;
        .more{
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            border-bottom: 4px solid #000;
            padding-bottom: 10px;
        }
        .recommend-con{
            width: 926px;
        }
        .reimg{
            width: 926px;
            height: 160px;
            margin-top: 50px;
        }
    }
}