.user-box{
    display: flex;
    border-top: 5px solid #000;
    margin-top: 77px;
    .setting{
        font-size: 50px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        margin: 60px 0 0 60px;
    }
    .setting-box{
        padding: 50px 0 50px 100px;
        width: 725px;
        // height: 292px;
        background: #FFFFFF;
        border: 2px solid #000000;
        margin: 102px 0 0 125px;
        display: flex;
        .avatar-border{
            width: fit-content;
            text-align: center;
            .user-avatar{
                width: 150px;
                height: 150px;
                border: 2px solid #000000;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
                .defaultimg{
                    width: fit-content;
                    height: fit-content;
                }
            }
            .changeimg{
                margin-top: 20px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                cursor: pointer;
            }
        }
        .setcontent{
            margin-left: 120px;
            >div{
                height: auto;
                display: flex;
                align-items: baseline;
                justify-content: space-between;
                margin-bottom: 20px;
                span{
                    font-size: 20px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                }
                
                .content{
                    margin-left: 20px;
                    font-size: 16px !important;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    width: 129px;
                    flex:1;
                    word-wrap:break-word; word-break:break-all;
                }
                img{
                    margin-left: 40px;
                    cursor: pointer;
                    width: fit-content;
                    height: fit-content;
                    margin-top: 5px;
                }
                input{
                    width: 140px;
                    height: 30px;
                    margin-left: 20px;
                    outline: none;
                   
                }
                .ant-form-item-explain.ant-form-item-explain-error{
                    margin-left: 20px;
                }
                .ant-form, .ant-form-horizontal{
                    display: inline-block;
                }
                .bianxie-active{
                    margin-top: -20px;
                }
            }
            .nickli-active{
                margin-bottom: 0;
                display: flex;
                align-items: baseline;
            }
        }
    }
}

//弹框通用
.ant-modal-close{
    color: #000000;
}
.ant-modal-title{
    text-align: center;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    letter-spacing: 1px;
    padding-top: 40px;
}
.ant-modal-header{
    border-bottom: none;
    border-radius: 0;
}
.ant-modal-footer{
    border-top: none;
    text-align: center;
    button:nth-of-type(1){
        display: none;
    }
    padding: 0;
    .ant-btn, .ant-btn-primary, button{
        margin-left: 0;
        width: 346px;
        height: 48px;
        background: #FFFFFF;
        border: 2px solid #D43826;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #D43826;
        outline: none;
        margin-top: 24px;
    }
}
.ant-modal-content{
    -webkit-box-shadow:none;
    box-shadow: none;
}

//昵称弹框
.user-avatar-box{
    width: 566px;
    height: 386px;
    background-color: #FFFFFF;
    border: 2px solid #000000;
    top: 50%;
    margin-top: -193px;
    
    .ant-modal-body{
        padding-left: 106px;
        .content{
            display: flex;
            .avatar-border{
                width: 150px;
                height: 150px;
                background: #FFFFFF;
                border: 2px solid #000000;
                // text-align: center;
                // line-height: 146px;
                display: flex;
                align-items: center;
                justify-content: center;
                img.userset{
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
            .update{
                margin-left: 60px;
                div{
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #000000;
                    line-height: 22px;
                    margin-bottom: 7px;
                }
                .checkImg{
                    width: 136px !important;
                    height: 34px !important;
                    border: 2px solid #000000;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #000000;
                    text-align: center;
                    line-height: 30px;
                    cursor: pointer;
                }
            }
            .ant-upload.ant-upload-select-picture-card{
                width: fit-content;
                height: fit-content;
                background-color: #FFFFFF;
                border: none;
            }
            
        }
    }
    
}

.phone, .email, .password, .user-avatar-box{
    width: 566px;
    height: 348px;
    background-color: #FFFFFF;
    border: 2px solid #000000;
    top: 50%;
    margin-top: -124px;
    .ant-modal-body{
        padding: 0 110px;
        input, input:focus {
            width: 346px;
            border: none;
            outline: none;
            border-bottom: 2px solid #000;
            padding: 6px 0;
            border-radius: 0;
        }

        ::-webkit-input-placeholder,
        input {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
        }
        .ant-col .ant-form-item-control{
            margin-bottom: 30px;
        }
        #code{
            position: relative;
            .vercode{
                position: absolute;
                right: 0;
                top: 0;
                min-width: 108px;
                height: 34px;
                background: #FFFFFF;
                border: 1px solid #000000;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #000000;
                border-radius: 0;
            }
        }
        button.yesok{
            margin-left: 0;
            width: 346px;
            height: 48px;
            background: #FFFFFF;
            border: 2px solid #D43826;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #D43826;
            outline: none;
            cursor: pointer;
        }
    }
    .content{
        .title{
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            text-align: center;
            margin-top: 4px;
        }
        
    }
}
.user-avatar-box{
    .yesok{
        margin-top: 24px;
    }
    .ant-modal-body{
        padding-left: 106px;
        padding-right: 0;
    }
}
.password{
    height: 500px;
    margin-top: -250px;
    .pwdcon{
        position: relative;
        span{
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            position: absolute;
            right: 0;
            top: 7px;
        }
        .pwdplace{
            display: none !important;
        }
    }
}

#testmobile:disabled, #mobile:disabled{
    background-color: #FFFFFF;
}

#testmobile::-webkit-input-placeholder, #mobile::-webkit-input-placeholder{
    background-color: #FFFFFF;
    color: #999999 !important;
}